<template>
  <div
    class="aircallphone-container"
    :class="visibilityClass"
    data-cy="aircall-phone"
  >
    <div id="phone"></div>
  </div>
</template>

<script>
import AircallPhone from "aircall-everywhere"
import { mapGetters } from "vuex"

export default {
  name: "AirCallPhone",
  computed: {
    ...mapGetters("aircall", ["isVisible", "callAsked"]),
    visibilityClass() {
      return this.isVisible ? "" : "d-none"
    },
  },
  watch: {
    callAsked() {
      if (this.callAsked) {
        let payload = {
          phone_number: "0170368526",
        }
        this.phone.send("dial_number", payload, (success, data) => {
          console.log(`calling ${payload.phone_number}`)
        })
      }
    },
  },
  methods: {
    onIncomingCall(callInfo) {
      console.log(`Call received from ${callInfo.from}`)

      // If phone is not visible, make it visible
      if (!this.isVisible) {
        this.$store.dispatch("aircall/changeVisibility", true)
      }
    },
  },
  async mounted() {
    // Note : saving the new AircallPhone instance into a data (rather than a local variable)
    // makes the feature not to work (for an unknown reason for now) !
    // await this.$store.dispatch("aircall/setPhone", "#phone")
    const phone = new AircallPhone({
      domToLoadPhone: "#phone",
    })
    this.phone = phone

    // Specify callback to call on "incoming call" event
    this.phone.on("incoming_call", this.onIncomingCall)
  },
}
</script>

<style lang="scss" scoped>
.aircallphone-container {
  position: fixed;
  top: 106px;
  right: 0;
  z-index: 30;
}
.d-none {
  display: none;
}
</style>
