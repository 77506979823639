<template>
  <div>
    <h4 v-if="getMessage" class="spinner__message">
      {{ getMessage }}
    </h4>
    <vue-spinner color="#1d2b4e"></vue-spinner>
  </div>
</template>

<script>
import VueSpinner from "vue-spinner/src/ScaleLoader"

export default {
  name: "Spinner",
  props: {
    message: { type: String, required: false },
    noMessage: { type: Boolean, required: false },
  },
  components: { VueSpinner },
  computed: {
    getMessage() {
      if (this.message) return this.message
      if (this.noMessage) return null
      return this.$t("spinner.default")
    },
  },
}
</script>

<style lang="scss" scoped>
.spinner__message {
  margin-bottom: 1rem;
  text-align: center;
}
</style>
}
