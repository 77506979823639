<template>
  <div class="">
    <div
      class="claim-summary accordion"
      :data-cy="`accordion-claim-${claim.id}`"
    >
      <div class="claim-summary__id">
        <h5 class="contract__data-name">
          {{ $t("contract.claims.id") }}
        </h5>
        <h3 class="contract__data-value">{{ claim.id }}</h3>
      </div>
      <div class="claim-summary__creation-date">
        <h5 class="contract__data-name">
          {{ $t("contract.claims.creationDate") }}
        </h5>
        <h3 class="contract__data-value">{{ claim.incident_date }}</h3>
      </div>
      <div class="claim-summary__short-desc">
        <h5 class="contract__data-name">
          {{ $t("contract.claims.shortDesc") }}
        </h5>
        <h3 class="contract__data-value">{{ claim.description }}</h3>
      </div>
      <div class="claim-summary__created-by">
        <h5 class="contract__data-name">
          {{ $t("contract.claims.createdBy") }}
        </h5>
        <h3 class="contract__data-value">{{ getCreatedBy }}</h3>
      </div>
      <div class="claim-summary__status">
        <h5 class="contract__data-name">{{ $t("contract.claims.status") }}</h5>
        <h3 :class="['contract__data-status', statusClass]">
          {{ statusLabel }}
        </h3>
      </div>
    </div>
    <div class="claim-logs panel">
      <div
        class="claim-logs__item"
        v-for="(log, index) in claim.logs"
        :key="log.name"
      >
        <h5
          class="contract__data-name"
          :data-cy="`claim-${claim.id}-log-${index + 1}`"
        >
          {{ log.name }}
        </h5>
        <h4 class="contract__data-value">{{ log.value }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
import ContractDataStatus from "../mixins/ContractDataStatus"

export default {
  name: "Claim",
  mixins: [ContractDataStatus],
  props: {
    claim: { type: Object, required: true },
  },
  computed: {
    getCreatedBy() {
      return `${this.claim.created_by.first_name} ${this.claim.created_by.last_name}`
    },
  },
}
</script>

<style lang="scss" scoped>
h5 {
  margin-bottom: 0.6rem;
}
h3 {
  font-size: 1.05em;
}
.claim-summary {
  background-color: white;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
  grid-template-areas: "id creation-date short-desc created-by status";
}

.claim-summary__id {
  grid-area: id;
}

.claim-summary__creation-date {
  grid-area: creation-date;
}

.claim-summary__short-desc {
  grid-area: short-desc;
}

.claim-summary__created-by {
  grid-area: created-by;
}

.claim-summary__status {
  grid-area: status;
}

.claim-logs {
  padding: 15px 25px 15px 25px;
  background-color: $purple20;
}

.claim-logs__item {
  display: grid;
  grid-template-columns: 1fr 5fr;
  padding-bottom: 1rem;
}

.claim-logs__item:last-child {
  padding-bottom: 0;
}
</style>
