<template>
  <div class="card">
    <div class="card__header">
      <h3 class="card__header__title">{{ title }}</h3>
      <em class="icon-pencil5 edit" v-if="false"></em>
      <em class="icon-eye view" v-if="false"></em>
    </div>
    <div class="card__data">
      <div class="card__data__main-part">
        <h5 class="contract__data-name">{{ mainData.name }}</h5>
        <h3 class="contract__data-value" :data-cy="`${mainData.dataCy}`">
          {{ mainData.value }}
        </h3>
      </div>
      <div
        class="card__data__misc-part"
        v-for="data in miscData"
        :key="data.name"
      >
        <div
          class="contract__data__with-icon contract__data__with-icon-tel"
          v-if="data.icon && data.icon === 'icon-phone-wave'"
          @click="launchAircall(data.value)"
        >
          <h5 class="contract__data-name">{{ data.name }}</h5>
          <h4 class="contract__data-value" :data-cy="`${data.dataCy}`">
            {{ data.value }}
          </h4>
          <em :class="data.icon"></em>
        </div>
        <div
          class="contract__data__with-icon"
          v-else-if="data.icon && data.icon !== 'icon-phone-wave'"
        >
          <h5 class="contract__data-name">{{ data.name }}</h5>
          <h4 class="contract__data-value" :data-cy="`${data.dataCy}`">
            {{ data.value }}
          </h4>
          <em :class="data.icon"></em>
        </div>
        <div v-else>
          <h5 class="contract__data-name">{{ data.name }}</h5>
          <h4 class="contract__data-value" :data-cy="`${data.dataCy}`">
            {{ data.value }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "ContractDataCard",
  props: {
    title: { type: String, required: true },
    mainData: { type: Object, required: true },
    miscData: { type: Array, default: () => [] },
  },
  computed: {
    ...mapGetters("aircall", ["isVisible"]),
  },
  methods: {
    launchAircall(phoneNumber) {
      this.$store.dispatch("aircall/makeCall", true)
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  display: grid;
  grid-template-rows: 1fr 9fr;
}

.card__header {
  background-color: $blue40;
  padding: 1rem;
  display: grid;
  grid-template-columns: 5fr 1fr 1fr;
  grid-column-gap: 0.2em;
  justify-items: stretch;
  align-items: center;
  color: white;

  i {
    font-size: 1.2rem;
  }
}

.card__header__title {
  text-transform: uppercase;
  font-weight: normal;
}

.edit {
  justify-self: end;
}

.view {
  justify-self: center;
}

.card__data {
  background-color: white;
  padding: 1rem;
}

.card__data__main-part {
  padding-bottom: 1rem;
}

.card__data__misc-part {
  padding-bottom: 1rem;
}

.card__data__misc-part:last-child {
  padding-bottom: 0;
}

// Add padding to match alignment between data with icon and data without icon
.contract__data-name {
  padding-top: 0.2rem;
  padding-left: 0.5rem;
}

// Add padding to match alignment between data with icon and data without icon
.contract__data-value {
  padding-left: 0.5rem;
  padding-bottom: 0.2rem;
}
</style>
