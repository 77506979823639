<template>
  <div>
    <h3 class="claims__title">{{ $t("contract.claims.title") }}</h3>
    <Claim
      class="claims__data"
      v-for="claim in claims"
      :key="claim.id"
      :claim="claim"
      :data-cy="`claim-${claim.id}`"
    ></Claim>
  </div>
</template>

<script>
import Claim from "./Claim"
import Accordion from "../mixins/Accordion"

export default {
  name: "ClaimsList",
  components: { Claim },
  mixins: [Accordion],
  props: {
    claims: {
      type: Array,
    },
  },
}
</script>

<style lang="scss" scoped>
.claims__title {
  padding-bottom: 0.5rem;
}

.claims__data {
  padding-bottom: 0.2rem;
}
</style>
