import sidebar from "./layout/sidebar.json"
import topbar from "./layout/topbar.json"
import button from "./button.json"
import contract from "./views/contract.json"
import spinner from "./spinner.json"

export default {
  topbar,
  sidebar,
  button,
  contract,
  spinner,
}
