import store from "@/store/index"
import i18n from "@/i18n/index"

function getI18nDefaultRoute(route) {
  const lang = store.getters["config/getCurrentLang"]
  return { path: `/${lang}${route.path}` }
}

function getI18nRoute(route) {
  // eslint-disable-next-line no-prototype-builtins
  if (!route.params || !route.params.hasOwnProperty("lang")) {
    if (!route.params) {
      route["params"] = {}
    }
    route.params["lang"] = store.getters["config/getCurrentLang"]
  }
  return route
}

const setLanguage = (to, from, next) => {
  // eslint-disable-next-line no-prototype-builtins
  const lang = to.params.hasOwnProperty("lang") ? to.params.lang : false

  if (lang && store.getters["config/isLangAvailable"](lang) === false) {
    return next(getI18nDefaultRoute(to))
  }

  if (lang) {
    if (lang === store.getters["config/getCurrentLang"]) {
      if (to.name !== undefined) {
        return next()
      }
      return next(getI18nRoute({ name: "404" }))
    }

    // eslint-disable-next-line no-prototype-builtins
    if (i18n.messages.hasOwnProperty(lang)) {
      // requested lang update
      console.log("setCurrentLanguage")
      store.dispatch("config/setCurrentLang", lang)
      return next()
    }
  }
  // lang unknown or missing set default one
  if (lang) {
    to.params.lang = store.getters["config/getCurrentLang"]
    next(to)
  } else {
    next(getI18nDefaultRoute(to))
  }
}

const beforeEnter = (to, from, next) => {
  setLanguage(to, from, next)
}

export default {
  beforeEnter,
}
