<template>
  <div class="contract-page">
    <Spinner v-if="isLoading" class="contract-page__spinner"></Spinner>
    <h2
      v-else-if="hasError(hardcodedContractId)"
      class="contract-page__error"
      data-cy="contract-error"
    >
      {{ error }}
    </h2>
    <div v-else class="contract">
      <h2 class="contract__title" data-cy="contract-title">{{ title }}</h2>
      <ButtonWithIcon
        class="contract__new-claim-button"
        icon="icon-file-add"
        :label="$t('button.new_claim')"
      />
      <div class="contract__data-cards">
        <ContractDataCard
          v-for="content in dataCardsContent"
          :key="content.title"
          :title="content.title"
          :main-data="content.mainData"
          :misc-data="content.miscData"
        >
        </ContractDataCard>
      </div>
      <ClaimsList class="contract__claims" :claims="claims"></ClaimsList>
      <DiariesList class="contract__diaries" :diaries="contract.logs">
      </DiariesList>
    </div>
  </div>
</template>

<script>
import ButtonWithIcon from "@/components/button/ButtonWithIcon"
import ContractDataCard from "../components/contract/ContractDataCard"
import ClaimsList from "../components/contract/ClaimsList"
import DiariesList from "../components/contract/DiariesList"
import { mapGetters } from "vuex"
import ContractDataCardContent from "../components/mixins/ContractDataCardContent"
import ContractService from "../services/business/ContractService"

export default {
  name: "Home",
  components: {
    ButtonWithIcon,
    ContractDataCard,
    ClaimsList,
    DiariesList,
  },
  mixins: [ContractDataCardContent],
  data() {
    return {
      contract: {},
      dataCardsContent: [],
      claims: [],
      isLoading: true,
      // TODO retrieve real contract id
      hardcodedContractId: 1,
    }
  },
  computed: {
    ...mapGetters("contract", ["getContract", "hasError", "getError"]),
    ...mapGetters("diary", ["getDiaries"]),
    title() {
      return `${this.$t("contract.record").toUpperCase()} N°${
        this.contract.id
      } - ${this.contract.identification.name} - ${
        this.contract.vehicle.make
      } ${this.contract.vehicle.model}`
    },
    error() {
      const error = this.getError(this.hardcodedContractId)
      if (error.status === 404) {
        return `${this.$t("contract.unknownContract")}`
      } else if (error.status === 422) {
        return error.data
      } else {
        return `${this.$t("contract.unknownError")}`
      }
    },
  },
  methods: {
    buildDataCardsContent(contract) {
      const dataCardsInputs = [
        {
          input: contract.identification,
          mainKey: "name",
          translationBasis: "contract.identification",
          dataCyPrefix: "identification",
        },
        {
          input: contract.vehicle,
          mainKey: "make",
          translationBasis: "contract.vehicle",
          dataCyPrefix: "vehicle",
        },
        {
          input: contract.terms,
          mainKey: "contractNum",
          translationBasis: "contract.terms",
          dataCyPrefix: "contract",
        },
        {
          input: contract.conditions,
          mainKey: "subscriptionDate",
          translationBasis: "contract.conditions",
          dataCyPrefix: "conditions",
        },
      ]

      dataCardsInputs.forEach((cardInput) => {
        this.dataCardsContent.push(this.buildDataCardContent(cardInput))
      })
    },
  },
  async created() {
    try {
      await this.$store.dispatch(
        "contract/getFullData",
        this.hardcodedContractId
      )
      // TODO don't hardcode contract id
      this.contract = ContractService.formatInfoForDisplay(
        this.getContract(this.hardcodedContractId)
      )
      this.buildDataCardsContent(this.contract)
      this.claims = this.contract.claims
      const logs = [
        {
          name: "Qui a appelé",
          value: "Garage des rosières",
        },
        {
          name: "Qui a déclaré le sinistre",
          value: "Celui qui a déclaré le sinistre",
        },
      ]
      this.claims.reduce((acc, k) => {
        k["logs"] = logs
        acc.push(k)
        return acc
      }, [])
      try {
        await this.$store.dispatch("diary/setDiaries", this.hardcodedContractId)
        this.contract["logs"] = this.getDiaries
      } catch (e) {
        console.log("an error occurred while fetching contract's logs")
      }
    } catch (e) {
      console.log("an error occurred while fetching contract's full data")
    } finally {
      this.isLoading = false
    }
  },
}
</script>

<style lang="scss" scoped>
.contract-page {
  background-color: $caarealightgray;
}

.contract-page__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.contract-page__error {
  position: absolute;
  left: 50%;
  margin-top: 1rem;
}
.contract {
  padding: 30px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: repeat(4, auto);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
  grid-template-areas:
    "title new-claim-button"
    "data-cards data-cards"
    "claims claims"
    "diaries diaries";
}

.contract__title {
  grid-area: title;
  justify-self: start;
  align-self: center;
  color: $blue100;
  font-weight: bold;
}

.contract__new-claim-button {
  grid-area: new-claim-button;
  justify-self: end;
  align-self: center;
}

.contract__data-cards {
  grid-area: data-cards;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1rem;
}

.contract__claims {
  grid-area: claims;
}

.contract__diaries {
  grid-area: diaries;
}
</style>
