import i18n from "@/i18n/index"
import axios from "axios"
import local_storage_names from "@/conf/local_storage_names"

// STATES (snake_case)
const state = {
  current_lang: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_CURRENT_LANGUAGE: (state, lang) => {
    state.current_lang = lang
  },
}

// ACTIONS (camelCase)
const actions = {
  _setLang({ commit }, lang) {
    localStorage.setItem(local_storage_names.lang, lang)
    i18n.locale = lang
    axios.defaults.headers.common["Accept-Language"] = lang
    document.querySelector("html").setAttribute("lang", lang)
    commit("SET_CURRENT_LANGUAGE", lang)
  },
  initI18n({ dispatch }) {
    const lang =
      localStorage.getItem(local_storage_names.lang) ||
      navigator.language.slice(0, 2) ||
      i18n.locale
    dispatch("_setLang", lang)
  },
  setCurrentLang({ state, dispatch }, lang) {
    if (state.current_lang === null) {
      throw Error(
        "You should initialize language with 'dispatch(\"config/initLang\")' first"
      )
    }
    dispatch("_setLang", lang)
  },
}

// GETTERS (camelCase)
const getters = {
  getCurrentLang: (state) => state.current_lang,
  // eslint-disable-next-line no-unused-vars
  isLangAvailable: (state) => (lang) => i18n.availableLocales.includes(lang),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
