import AbstractService from "../AbstractService"
import i18n from "@/i18n/index"

class ContractUtils extends AbstractService {
  formatInfoForDisplay(contract) {
    // determine the name of the coverage type criteria
    const coverageType = `coverage_type_${contract.sale.product_lines[0].product.id}`
    const engineType = i18n.t(
      `contract.vehicle.engineType.${contract.sale.vehicle.engine}`
    )

    const generalInfo = {
      identification: {
        name: `${contract.sale.beneficiary.user.first_name} ${contract.sale.beneficiary.user.last_name}`,
        address: contract.sale.beneficiary.address_1,
        zipCode: `${contract.sale.beneficiary.zip_code}, ${contract.sale.beneficiary.city}`,
        mobile: contract.sale.beneficiary.mobile,
        email: contract.sale.beneficiary.user.email,
      },
      vehicle: {
        make: contract.sale.vehicle.make,
        model: `${contract.sale.vehicle.model} ${contract.sale.vehicle.version}`,
        plate: contract.sale.vehicle.plate,
        engine: `${engineType} - ${contract.sale.vehicle.fiscal_hp} ${i18n.t(
          "contract.vehicle.fiscalHp"
        )}`,
        mecDate: contract.sale.vehicle.first_release_date,
        // TODO compute date of end of warranty
        manufacturerWarrantyEnd: contract.sale.vehicle.first_release_date,
      },
      terms: {
        contractNum: contract.num,
        productName: contract.sale.product_lines[0].product.name,
        // TODO type de contrat ?
        contractType: "TBD",
        // TODO grand compte ?
        keyAccount: "TBD",
        // TODO termes ?
        terms: "TBD",
        coverageType:
          contract.sale.product_lines[0].config.coefficient.values[
            coverageType
          ],
      },
      conditions: {
        subscriptionDate: contract.subscription,
        // TODO debut de police ?
        policyStart: "TBD",
        // TODO fin de police ?
        policyEnd: "TBD",
        // TODO km a la souscription
        subscriptionKm: "TBD",
        deductibleCurrency: `${contract.sale.product_lines[0].config.coefficient.values.deductible_currency} €`,
        status: contract.is_active
          ? i18n.t("contract.active")
          : i18n.t("contract.inactive"),
      },
    }
    return { id: contract.id, ...generalInfo, claims: contract.claims }
  }
}

let ContractService = new ContractUtils()
export default ContractService
