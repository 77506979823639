// To implement an accordion :
// - add the class 'accordion' to the element that will always remain visible
// - add the class 'panel' to the element whose visibility will be toggled on click event
// - import this mixin in the Vue file that needs to implement an accordion
//
// Limitation : the 'panel' element must be the first element after the 'accordion' element
//
// (The CSS classes 'accordion' and 'panel' are defined in _accordion.scss)

export default {
  data() {
    return {
      accordions: [],
    }
  },
  mounted() {
    // Get all the accordions on the page
    this.accordions = document.getElementsByClassName("accordion")

    // To each accordion, attach a callback function that will be executed on every click event
    // This function simply toggles the visibility of the 'panel' element
    this.accordions.forEach((accordion) => {
      accordion.addEventListener("click", function () {
        // Toggle the 'active' class on the accordion (useful if you want to apply some css)
        this.classList.toggle("active")

        // Get the 'panel' element then toggle its visibility
        var panel = this.nextElementSibling
        if (panel.style.display === "block") {
          panel.style.display = "none"
        } else {
          panel.style.display = "block"
        }
      })
    })
  },
}
