import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import urls from "./conf/api.urls"
import UrlService from "@/services/technical/UrlService"
import AxiosHelper from "@/helpers/axios.helper"
import i18n from "@/i18n/index"
import Spinner from "@/components/utils/Spinner"

Vue.config.productionTip = false
UrlService.initialize(urls)
AxiosHelper.initialize()
store.dispatch("config/initI18n")
Vue.component("Spinner", Spinner)
Vue.filter("getFrenchDate", (value) => {
  if (!value) return ""
  const options = { day: "numeric", month: "numeric", year: "numeric" }
  let date = new Date(value)
  return date.toLocaleDateString("fr-FR", options)
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app")
