export default {
  props: {
    claim: { type: Object, required: true },
  },
  computed: {
    statusClass() {
      // TODO define color of each status
      return this.claim.current_status === "DONE"
        ? "contract__data-status__done"
        : "contract__data-status__open"
    },
    statusLabel() {
      // TODO define translated label of each status
      return this.claim.current_status === "DONE"
        ? this.$t("contract.claims.statusValues.done")
        : this.$t("contract.claims.statusValues.open")
    },
  },
}
