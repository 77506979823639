// STATES (snake_case)
const state = () => ({
  is_visible: false,
  call_asked: false,
})

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_VISIBILITY(state, isVisible) {
    state.is_visible = isVisible
  },
  MAKE_CALL(state, bool) {
    state.call_asked = bool
  },
}

// ACTIONS (camelCase)
const actions = {
  changeVisibility({ commit }, isVisible) {
    commit("SET_VISIBILITY", isVisible)
    if (!isVisible) {
      commit("MAKE_CALL", false)
    }
  },
  toggleVisibility({ dispatch, state }) {
    dispatch("changeVisibility", !state.is_visible)
  },
  makeCall({ commit }, bool) {
    commit("SET_VISIBILITY", true)
    commit("MAKE_CALL", bool)
  },
}

// GETTERS (camelCase)
const getters = {
  isVisible: (state) => state.is_visible,
  callAsked: (state) => state.call_asked,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
