import Vue from "vue"
import VueRouter from "vue-router"
import Layout from "../layouts/Layout"
import guards from "./guards"
import Home from "@/views/Home"
import Contract from "@/views/Contract"

Vue.use(VueRouter)

const routes = [
  {
    path: "/:lang",
    name: "lang",
    beforeEnter: guards.beforeEnter,
    redirect: { name: "privateZone" },
    component: {
      render(c) {
        return c("router-view")
      },
    },
    children: [
      {
        path: "",
        name: "privateZone",
        component: Layout,
        redirect: { name: "home" },
        children: [
          {
            path: "",
            name: "home",
            component: Home,
          },
          {
            path: "contracts/:id",
            name: "contracts",
            component: Contract,
          },
        ],
      },
      {
        path: "*",
        beforeEnter: guards.beforeEnter,
      },
    ],
  },
  {
    path: "*",
    beforeEnter: guards.beforeEnter,
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
