import Vue from "vue"
import UrlService from "../services/technical/UrlService"
import HttpService, { HttpError } from "@/services/technical/HttpService"

// STATES (snake_case)
const state = {
  contracts: null,
  errors: null,
  search_error: null,
  search_results: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_CONTRACT(state, contract) {
    if (state.contracts === null) {
      state.contracts = {}
    }
    Vue.set(state.contracts, contract.id, contract)
  },
  SET_ERROR(state, error) {
    if (state.errors === null) {
      state.errors = {}
    }
    Vue.set(state.errors, error.contractId, error.details)
  },
  SET_SEARCH_ERROR(state, error) {
    state.search_error = error
  },
  RESET_SEARCH_ERROR(state, error) {
    state.search_error = null
  },

  SET_CONTRACTS_SEARCH_RESULT(state, contracts) {
    if (state.search_results === null) {
      state.search_results = {}
    }
    for (let contractId in contracts) {
      Vue.set(state.search_results, contractId, contracts[contractId])
    }
  },
  RESET_CONTRACTS_SEARCH_RESULT(state) {
    state.search_results = null
  },
}

// ACTIONS (camelCase)
const actions = {
  async getFullData({ commit }, contractId) {
    try {
      const contractFullData = await HttpService.get(
        UrlService.render("contractFull", { id: contractId })
      )
      commit("SET_CONTRACT", contractFullData)
    } catch (e) {
      if (e instanceof HttpError) {
        if (e.status === 422 || e.status === 404) {
          commit("SET_ERROR", { contractId: contractId, details: e })
          return
        }
      }
      console.error("failed: ", e.response.status)
      throw e
    }
  },
  resetSearch({ commit }) {
    commit("RESET_CONTRACTS_SEARCH_RESULT")
    commit("RESET_SEARCH_ERROR")
  },

  async search({ commit }, searchText) {
    commit("RESET_CONTRACTS_SEARCH_RESULT")
    try {
      const contracts = await HttpService.get(
        UrlService.render(
          "contractSearch",
          {},
          { search_text: searchText, full: 1 }
        )
      )
      commit("SET_CONTRACTS_SEARCH_RESULT", contracts)
      // noinspection JSUnresolvedVariable
      return contracts.length > 0
    } catch (e) {
      if (e instanceof HttpError) {
        if (e.status === 422 || e.status === 404) {
          commit("SET_SEARCH_ERROR", e.data)
          return
        }
      }
      console.error("failed: ", e.response.status)
      throw e
    }
  },
}

// GETTERS (camelCase)
const getters = {
  getContract: (state) => (id) =>
    state.contracts !== null && state.contracts.hasOwnProperty(id)
      ? state.contracts[id]
      : null,
  getContractsSearchResult: (state) =>
    state.search_results !== null && state.search_results
      ? state.search_results
      : null,
  hasError: (state) => (id) =>
    state.errors !== null && state.errors.hasOwnProperty(id),
  getError: (state, getters) => (id) =>
    getters.hasError(id) ? state.errors[id] : null,
  hasSearchError: (state) => state.search_error !== null,
  getSearchError: (state, getters) =>
    getters.hasSearchError ? state.search_error : null,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
