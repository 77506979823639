<template>
  <button
    @click="$emit('click')"
    type="button"
    :class="['btn-container', 'button', ...buttonClass]"
  >
    <em :class="icon"></em>
    <span>{{ label }}</span>
  </button>
</template>

<script>
export default {
  name: "ButtonWithIcon",
  props: {
    icon: { type: String, required: true },
    label: { type: String, required: true },
    buttonClass: { type: String, default: "btn-primary" },
  },
}
</script>

<style lang="scss" scoped>
.btn-container {
  display: flex;
  align-items: center;
  min-width: 11em;

  em {
    padding-left: 0.5rem;
    font-size: 1.6rem;
  }

  span {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
</style>
