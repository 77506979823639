import Vue from "vue"
import Vuex from "vuex"
import config from "@/store/config"
import aircall from "@/store/aircall"
import contract from "@/store/contract"
import diary from "@/store/diary"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    config,
    aircall,
    contract,
    diary,
  },
})
