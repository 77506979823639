export default {
  methods: {
    getIconForData(dataName) {
      if (dataName === "mobile") {
        return "icon-phone-wave"
      } else if (dataName === "email") {
        return "icon-envelope"
      } else {
        return null
      }
    },
    buildDataCardMiscData(cardInputs) {
      let miscData = []
      Object.entries(cardInputs.input).forEach(([key, value]) => {
        if (key !== cardInputs.mainKey) {
          miscData.push({
            name: this.$t(`${cardInputs.translationBasis}.${key}`),
            value: value ? value : this.$t("contract.unknownValue"),
            icon: this.getIconForData(key),
            dataCy: `${cardInputs.dataCyPrefix}-${key}`,
          })
        }
      })
      return miscData
    },
    buildDataCardContent(cardInputs) {
      return {
        title: this.$t(`${cardInputs.translationBasis}.title`),
        mainData: {
          name: this.$t(`${cardInputs.translationBasis}.${cardInputs.mainKey}`),
          value: cardInputs.input[cardInputs.mainKey],
          dataCy: `${cardInputs.dataCyPrefix}-${cardInputs.mainKey}`,
        },
        miscData: this.buildDataCardMiscData(cardInputs),
      }
    },
  },
}
